import { FC } from "react";

export interface ExternalLinkProps {
  link: string;
  target?: string;
}

export const ExternalLink: FC<ExternalLinkProps> = ({
  link,
  children,
  target = "_blank",
}) => (
  <a href={link} target={target} rel="noreferrer">
    {children}
  </a>
);
