import React, { Fragment } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import HomeIcon from "@mui/icons-material/Home";
import Newspaper from "@mui/icons-material/Newspaper";
import AccountTree from "@mui/icons-material/AccountTree";
import Drafts from "@mui/icons-material/Drafts";
import Flag from "@mui/icons-material/Flag";
import LocalPhone from "@mui/icons-material/LocalPhone";
import { ExternalLink } from "../Link/ExternalLink";
import { NotificationButton } from "../NotificationButton";
import { usePortalProvider } from "../../providers/portal-provider";

export const TopBar = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(800));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down(1150));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { profile } = usePortalProvider();

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    { label: "Início", icon: <HomeIcon />, path: "/app/catalog" },
    {
      label: "Secretarias e órgãos",
      icon: <AccountTree />,
      path: "/app/catalog/secretarias-e-orgaos",
    },
    {
      label: "Cartas de Serviços",
      icon: <Drafts />,
      path: "/app/catalog/carta-de-servicos",
    },
    { label: "Notícias", icon: <Newspaper />, path: "/app/catalog/orgao" },
    { label: "O Governo", icon: <Flag />, path: "/app/catalog/orgao" },
    { label: "Informações", icon: <LocalPhone />, path: "/app/catalog/orgao" },
  ];

  const visibleItemCount = isSmallScreen ? 0 : isMediumScreen ? 4 : 6;
  const visibleItems = menuItems.slice(0, visibleItemCount);
  const hiddenItems = menuItems.slice(visibleItemCount);

  return (
    <AppBar
      position="static"
      sx={{
        height: 54,
        backgroundColor: "#143971",
        borderBottom: "3px solid #ff891b",
      }}
      className="app-bar-container"
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          height: "100%",
          padding: "0px !important",
        }}
      >
        <Box
          sx={{
            display: "flex",
            marginTop: isSmallScreen ? "" : "-10px",
            "& svg": {
              color: "#fff",
              height: "20px",
            },
          }}
        >
          {[
            { label: "Facebook", Icon: FacebookIcon },
            { label: "Twitter", Icon: TwitterIcon },
            { label: "Instagram", Icon: InstagramIcon },
            { label: "Youtube", Icon: YouTubeIcon },
          ].map(({ label, Icon }, index) => (
            <ExternalLink link="" key={index}>
              <IconButton
                color="inherit"
                aria-label={label}
                sx={{ paddingX: "4px" }}
              >
                <Icon />
              </IconButton>
            </ExternalLink>
          ))}
        </Box>

        <Box
          sx={{
            display: isSmallScreen ? "none" : "flex",
            flex: 1,
            justifyContent: "space-evenly",
            alignItems: "center",
            overflow: "hidden",
            marginTop: "-10px",
          }}
        >
          {visibleItems.map((item, index) => (
            <Fragment key={index}>
              <ExternalLink link={item.path} target="_self">
                <Box
                  key={item.label}
                  sx={{ display: "flex", alignItems: "center", color: "#fff" }}
                >
                  <IconButton
                    color="inherit"
                    aria-label={item.label}
                    sx={{ marginRight: 1, "& svg": { height: "20px" } }}
                  >
                    {item.icon}
                  </IconButton>
                  <Typography
                    variant="body1"
                    component="div"
                    sx={{ cursor: "pointer", fontSize: "14px" }}
                  >
                    {item.label}
                  </Typography>
                </Box>
              </ExternalLink>
              {index < visibleItems.length - 1 && (
                <Box
                  sx={{
                    marginX: 0,
                    width: "1px",
                    height: "24px",
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                  }}
                />
              )}
            </Fragment>
          ))}
        </Box>

        {profile?.preferred_username && (
          <Box sx={{ marginTop: isSmallScreen ? "" : "-10px" }}>
            <NotificationButton
              subscriberId={profile.preferred_username as string}
            />
          </Box>
        )}
        {!!hiddenItems.length && (
          <IconButton
            color="inherit"
            aria-label="menu"
            onClick={handleMenuOpen}
            sx={{ marginTop: isSmallScreen ? "" : "-10px" }}
          >
            <MenuIcon />
          </IconButton>
        )}

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {hiddenItems.map((item) => (
            <ExternalLink key={item.label} target="_self" link={item.path}>
              <MenuItem sx={{ color: "#000000DE" }}>{item.label}</MenuItem>
            </ExternalLink>
          ))}
        </Menu>
      </Toolbar>
    </AppBar>
  );
};
