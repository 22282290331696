import {
  NotificationBell,
  NovuProvider,
  PopoverNotificationCenter,
} from "@novu/notification-center";
import { FC } from "react";

export type NotificationButtonProps = {
  subscriberId: string;
};

const appId = process.env.REACT_APP_NOTIFICATION_APP_ID!;
const wsUrl = process.env.REACT_APP_NOTIFICATION_WS_URL!;
const backendUrl = process.env.REACT_APP_NOTIFICATION_URL!;

export const NotificationButton: FC<NotificationButtonProps> = ({
  subscriberId,
}) => (
  <NovuProvider
    subscriberId={subscriberId}
    applicationIdentifier={appId}
    socketUrl={wsUrl}
    backendUrl={backendUrl}
    i18n={{
      translations: {
        notifications: "Notificações",
        markAllAsRead: "Marcar tudo como lido",
        removeMessage: "Remover Mensagem",
        markAsRead: "Marcar como Lido",
        markAsUnRead: "Marcar como Não Lido",
        settings: "Configurações",
        noNewNotification: "Não há novas notificações",
      },
      lang: "pt",
    }}
  >
    <PopoverNotificationCenter colorScheme="light" footer={() => <></>}>
      {({ unseenCount }) => <NotificationBell unseenCount={unseenCount} />}
    </PopoverNotificationCenter>
  </NovuProvider>
);
